import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import LockerControlSystemPage from './pages/LockerControlSystemPage';
import { AuthProvider, useAuth } from './context/authContext';
import LockerMapPage from './pages/LockerMapPage';

// PrivateRoute component
const PrivateRoute = ({ children }) => {
  const { authState } = useAuth();
  return authState.token ? children : <Navigate to="/" />;
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route 
            path="/lockers" 
            element={
              <PrivateRoute>
                <LockerControlSystemPage />
              </PrivateRoute>
            } 
          />
          <Route 
            path="/map" 
            element={
              <PrivateRoute>
                <LockerMapPage />
              </PrivateRoute>
            } 
          />
          {/* Add more routes as needed */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;