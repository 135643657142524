import React, { createContext, useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

const isValidToken = (token) => {
  if (!token) return false;
  const parts = token.split('.');
  return parts.length === 3;
};

export const AuthProvider = ({ children }) => {
  const token = localStorage.getItem('token');
  const [authState, setAuthState] = useState({
    token: token,
    user: isValidToken(token) ? jwtDecode(token) : null,
    fullName: sessionStorage.getItem('fullName'),
    userStatus: sessionStorage.getItem('userStatus'),
    userRole: sessionStorage.getItem('userRole'),
    userHistory: sessionStorage.getItem('userHistory'),
    userId: sessionStorage.getItem('userId'),
    badge: sessionStorage.getItem('userBadge'),
  });
  const [error, setError] = useState(null);

  const loginUser = async (username, password) => {
    setError(null); // Clear any previous errors
    console.log('Login attempt for username:', username);
    try {
      const response = await axios.post('https://serverlocker.winglet.app/login', 
        { username, password },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      
      console.log('Login response:', JSON.stringify(response.data));
      
      if (response.data && response.data.token) {
        localStorage.setItem('token', response.data.token);
        sessionStorage.setItem('fullName', response.data.fullName);
        sessionStorage.setItem('userStatus', response.data.userStatus);
        sessionStorage.setItem('userRole', response.data.userRole);
        sessionStorage.setItem('userHistory', response.data.userHistory);
        sessionStorage.setItem('userId', response.data.userId);
        sessionStorage.setItem('userBadge', response.data.userBadge);
        setAuthState({
          token: response.data.token,
          user: jwtDecode(response.data.token),
          fullName: response.data.fullName,
          userStatus: response.data.userStatus,
          userRole: response.data.userRole,
          userHistory: response.data.userHistory,
          userId: response.data.userId,
          badge: response.data.userBadge,
        });
        console.log('Login successful');
        return true;
      } else {
        throw new Error('Token not received in the response');
      }
    } catch (error) {
      console.error("Login failed:", error.response ? error.response.data : error.message);
      setError(error.response ? error.response.data.message : error.message);
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    sessionStorage.clear();
    setAuthState({ token: null, user: null });
  };

  return (
    <AuthContext.Provider value={{ authState, loginUser, logout, error }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};