// pages/LoginPage.js
import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../context/authContext';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CssBaseline from '@mui/material/CssBaseline';
import { Grid2 } from '@mui/material';
import logo from '../assets/images/logologin.png'; // Replace with your image path

export default function LoginPage() {
  const { loginUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setIsLoggedIn(false);
      navigate('/');
    }
  }, [navigate]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    await loginUser(username, password);
    navigate('/lockers'); // Navigate to lockers page after successful login
  };

  return (
    <Box
      sx={{
        backgroundColor: '#FFF',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img
            src={logo} // Replace with your image path
            alt="Login"
            style={{ width: '100%', height: '100%', margin: '1rem' }}
          />
          <Typography component="h1" variant="h5" color="white">
            Login
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Nome de usuário"
              name="username"
              placeholder='Digite seu nome de usuário'
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              variant='standard'

            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder='Digite sua senha'
              label="Senha"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant='standard'

            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            <Grid2 container>
              <Grid2 item>
                {/* Add any additional links or actions here */}
              </Grid2>
            </Grid2>
          </Box>
        </Box>
        <Typography variant="body2" color="textSecondary" align="center" sx={{
          color: '#11111f',
          marginTop: '2rem',
          fontSize: '0.7rem',
        }}>
        V0.1 - "Beta" - Powered by winglet - WFS|Orbital
      </Typography>
      </Container>

    </Box>

  );
}