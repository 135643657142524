import React, { useState, useContext } from 'react';
import { Modal, Box, Typography, TextField, Button, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import { AuthContext } from '../context/authContext';

const UserProfileModal = ({ open, onClose, fullName, userStatus, userBadge }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const { authState } = useContext(AuthContext);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');

  const handlePasswordChange = async () => {
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    try {
      const response = await axios.post('https://serverlocker.winglet.app/change_password', {
        userBadge,
        newPassword: password,
      }, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
          'Content-Type': 'application/json'
        }
      });

      if (response.status === 200) {
        setAlertMessage('Senha Alterada com Sucesso!');
        setAlertOpen(true);
        setAlertSeverity('success');
        setPassword('');
        setConfirmPassword('');
      } else {
        setAlertMessage('Ops! Algo deu errado ao alterar a senha');
        setAlertOpen(true);
        setAlertSeverity('error');
      }
    } catch (error) {
        setAlertMessage('Ops! Algo deu errado ao alterar a senha');
        setAlertOpen(true);
        setAlertSeverity('error');
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ 
        position: 'absolute', 
        top: '50%', 
        left: '50%', 
        transform: 'translate(-50%, -50%)', 
        width: 400, 
        bgcolor: 'background.paper', 
        boxShadow: 24, 
        p: 4, 
        outline: "none",
        borderRadius: 3
      }}>
        <Typography variant="h6" component="h2" gutterBottom>
          {fullName}
        </Typography>
        <Box mb={2}>
          <Typography variant="body1" component="p">
            <strong>STATUS:</strong> {userStatus === "active" ? "ATIVO" : userStatus === "inactive" ? "Inativo" : "Desconhecido"}
          </Typography>
          <Typography variant="body1" component="p">
            <strong>MATRÍCULA:</strong> {userBadge}
          </Typography>
        </Box>
        <Box mb={2}>
          <TextField
            margin="normal"
            fullWidth
            label="Digite a nova senha"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={alertOpen}
          />
          <TextField
            margin="normal"
            fullWidth
            label="Confirme a nova senha"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={alertOpen}
          />
        </Box>
        <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
          <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
            {alertMessage}
          </Alert>
        </Snackbar>
        <Box mt={2}>
          <Button
            variant="contained"
            color={password !== confirmPassword ? 'error' : 'success'}
            onClick={handlePasswordChange}
            disabled={password !== confirmPassword || password === ''}
            fullWidth
          >
            Alterar Senha
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default UserProfileModal;