import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Divider, Button, TextField, MenuItem, Select, InputLabel, FormControl, List, ListItem, ListItemText } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { format, startOfDay, endOfDay } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import * as XLSX from 'xlsx';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const AllLockersHistoryModal = ({ open, handleClose, lockerData }) => {
  const [selectedLocker, setSelectedLocker] = useState('');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(new Date()); // Set default value to current date
  const [userId, setUserId] = useState('');
  const [filteredHistory, setFilteredHistory] = useState([]);

  useEffect(() => {
    console.log('Locker data:', lockerData);
    const filterHistory = () => {
      const allHistory = lockerData.flatMap(locker => {
        if (typeof locker.locker_history === 'string') {
          try {
            const parsedHistory = JSON.parse(locker.locker_history);
            console.log(`Processing locker ${locker.number}:`, parsedHistory);
            return parsedHistory.map(entry => ({
              ...entry,
              lockerNumber: locker.number // Include locker number in each history entry
            }));
          } catch (error) {
            console.error(`Error parsing locker history for locker ${locker.number}:`, error);
            return [];
          }
        }
        return [];
      });
      console.log('All History:', allHistory);

      const filtered = allHistory.filter(entry => {
        if (!entry || !entry.dateTime) return false; // Filter out null or undefined entries
        const entryDate = new Date(entry.dateTime);
        const matchesLocker = selectedLocker ? entry.lockerNumber === selectedLocker : true;
        const matchesDate = (!startDate || entryDate >= startOfDay(startDate)) && (!endDate || entryDate <= endOfDay(endDate));
        const matchesUserId = userId ? entry.employeeId.includes(userId) : true;
        console.log(`Entry: ${entry.employeeId}, Matches User ID: ${matchesUserId}`);
        return matchesLocker && matchesDate && matchesUserId;
      });

      console.log('Filtered History:', filtered);
      return filtered;
    };

    setFilteredHistory(filterHistory());
  }, [selectedLocker, startDate, endDate, userId, lockerData]);

  useEffect(() => {
    // Initialize filteredHistory with all history entries when the modal is opened
    if (open) {
      const allHistory = lockerData.flatMap(locker => {
        if (typeof locker.locker_history === 'string') {
          try {
            const parsedHistory = JSON.parse(locker.locker_history);
            console.log(`Processing locker ${locker.number} on open:`, parsedHistory);
            return parsedHistory.map(entry => ({
              ...entry,
              lockerNumber: locker.number // Include locker number in each history entry
            }));
          } catch (error) {
            console.error(`Error parsing locker history for locker ${locker.number}:`, error);
            return [];
          }
        }
        return [];
      });
      console.log('All History on Open:', allHistory);
      setFilteredHistory(allHistory);
    }
  }, [open, lockerData]);

  const exportToExcel = () => {
    // Define the desired columns and their order
    const columns = [
      { header: 'Armário', key: 'lockerNumber' },
      { header: 'Status', key: 'type' },
      { header: 'Colete', key: 'employeeId' },
      { header: 'Data e Hora', key: 'dateTime' },
      { header: 'Responsável', key: 'fullName' },
      { header: 'Matrícula Orbital', key: 'userBadge' }
    ];
  
    // Map the filteredHistory to the new structure
    const data = filteredHistory.map(entry => ({
      lockerNumber: entry.lockerNumber.replace(/^[AB]/, ''), // Remove leading A or B
      type: entry.type === 'assign' ? 'Utilizado' : 'Liberado',
      employeeId: entry.employeeId || 'N/A',
      dateTime: entry.dateTime ? format(new Date(entry.dateTime), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR }) : 'N/A',
      fullName: entry.fullName || 'N/A',
      userBadge: entry.userBadge || 'N/A'
    }));
  
    // Create a new worksheet
    const worksheet = XLSX.utils.aoa_to_sheet([
      columns.map(col => col.header), // Add headers
      ...data.map(entry => columns.map(col => entry[col.key])) // Add data rows
    ]);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'History');

    // Write the workbook to a file
    XLSX.writeFile(workbook, 'lockers_history.xlsx');
  };

  const resetFilters = () => {
    setSelectedLocker('');
    setStartDate(null);
    setEndDate(new Date()); // Reset to current date
    setUserId('');
  };

  const groupedHistory = filteredHistory.reduce((acc, entry) => {
    if (!acc[entry.lockerNumber]) {
      acc[entry.lockerNumber] = [];
    }
    acc[entry.lockerNumber].push(entry);
    return acc;
  }, {});

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style} borderRadius={3}>
        <Typography id="modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
          Histórico Geral
        </Typography>
        <Divider sx={{ marginBottom: '10px' }} />
        <Typography variant="body2" sx={{marginBottom: '5px'}}>
                Filtros:
              </Typography>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }} borderRadius={3}>
            <FormControl sx={{ width:'32%' }}>
              <InputLabel id="locker-select-label">Armário</InputLabel>
              <Select
                labelId="locker-select-label"
                value={selectedLocker}
                onChange={(e) => setSelectedLocker(e.target.value)}
                label="Armário"
              >
                <MenuItem value=""><em>Todos</em></MenuItem>
                {lockerData.map(locker => (
                  <MenuItem key={locker.id} value={locker.number}>{locker.number.replace(/^[AB]/, '')}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <DatePicker
              label="Data Inicial"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} sx={{ width: '30%'}} />}
              inputFormat="dd/MM/yyyy"
              sx={{ width: '32%' }}
            />
            <DatePicker
              label="Data Final"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} sx={{ width: '30%' }} />}
              inputFormat="dd/MM/yyyy"
              disableFuture={true}
              sx={{ width: '32%' }}
            />
          </Box>
          <TextField
            label="Colete"
            value={userId}
            onChange={(e) => setUserId(e.target.value)}
            fullWidth
            sx={{ marginBottom: '10px' }}
          />
        </LocalizationProvider>
        <Button variant="outlined" color="secondary" onClick={resetFilters} fullWidth={true} sx={{ marginBottom: '10px' }}>
          Resetar Filtros
        </Button>
        <Box sx={{ display: 'flex', justifyContent: 'center', margin:"2px" }}>
        <Typography variant="body">
                        Pré Visualização:
                        </Typography>
                        </Box>
        <Divider sx={{ marginBottom: '10px'}} />
        <Box sx={{ maxHeight: '40vh', overflow: 'auto', scrollBehavior:'smooth', marginBottom: '10px', border:"1px", borderStyle:"solid", borderColor:'#E0E0E0', borderRadius:'5px', padding:'5px' }}>
          {Object.keys(groupedHistory).map(lockerNumber => (
            <Box key={lockerNumber} sx={{ marginBottom: '20px' }}>
              <Typography variant="h6">
                Armário: {lockerNumber.replace(/^[AB]/, '')}
              </Typography>
              <List>
                {groupedHistory[lockerNumber].map((entry, index) => (
                  <ListItem key={index} sx={{ marginBottom: '10px' }} divider={true}>
                    <ListItemText
                    inset={true}
                      secondary={
                        <>
                        <Typography variant="body2">
                        <strong>Status:</strong> {entry.type === 'assign' ? 'Utilizado' : 'Liberado'}
                        </Typography>
                          {entry.type === 'assign' && (
                            <Typography variant="body2">
                              <strong>Colete:</strong> {entry.employeeId || 'N/A'}
                            </Typography>
                          )}
                          <Typography variant="body2">
                            <strong>Data e Hora:</strong> {entry.dateTime ? format(new Date(entry.dateTime), 'dd/MM/yyyy HH:mm:ss', { locale: ptBR }) : 'N/A'}
                          </Typography>
                          <Typography variant="body2">
                            <strong>Responsável:</strong> {entry.fullName || 'N/A'}, Matrícula Orbital: {entry.userBadge || 'N/A'}
                          </Typography>
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          ))}
        </Box>
        <Button variant="contained" disabled={filteredHistory.length <= 0} color="primary" onClick={exportToExcel} fullWidth={true} sx={{ marginTop: '10px', alignSelf: 'center' }}>
          Exportar para Excel
        </Button>
      </Box>
    </Modal>
  );
};

export default AllLockersHistoryModal;