import React, { useEffect, useState, useContext } from 'react';
import { AppBar, Toolbar, Typography, Button, Badge, TextField, Divider, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { MapContainer, Marker, Popup, useMapEvents } from 'react-leaflet';
import axios from 'axios';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { AuthContext } from '../context/authContext';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/images/logo.png';
import smallAvailableImage from '../assets/images/sa.png';
import smallOccupiedImage from '../assets/images/so.png';
import bigAvailableImage from '../assets/images/ba.png';
import bigOccupiedImage from '../assets/images/bo.png';

// Custom hook to manage dynamic icon sizes
const useDynamicIconSize = (zoom) => {
  const baseSize = 30; // Base size for small icons
  const bigBaseSize = baseSize * 1.2; // Base size for big icons
  const scaleFactor = Math.pow(2, zoom); // Scale factor based on zoom level

  const smallIconSize = [baseSize * scaleFactor, baseSize * scaleFactor];
  const bigIconSize = [bigBaseSize * scaleFactor, bigBaseSize * scaleFactor];

  const createIcon = (url, size) => new L.Icon({
    iconUrl: url,
    iconSize: size,
    iconAnchor: [size[0] / 2, size[1] / 2],
    popupAnchor: [0, -size[1] / 2],
  });

  return {
    smallFreeIcon: createIcon(require('../assets/images/sa.png'), smallIconSize),
    smallOccupiedIcon: createIcon(require('../assets/images/so.png'), smallIconSize),
    bigFreeIcon: createIcon(require('../assets/images/ba.png'), bigIconSize),
    bigOccupiedIcon: createIcon(require('../assets/images/bo.png'), bigIconSize),
  };
};

function ZoomListener({ setZoom }) {
  const map = useMapEvents({
    zoomend: () => {
      setZoom(map.getZoom());
    },
  });
  return null;
}

export default function LockerMapPage() {
  const [lockers, setLockers] = useState([]);
  const [zoom, setZoom] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedLocker, setSelectedLocker] = useState(null);
  const [employeeId, setEmployeeId] = useState('');
  const { user, authState, logout } = useContext(AuthContext);
  const { smallFreeIcon, smallOccupiedIcon, bigFreeIcon, bigOccupiedIcon } = useDynamicIconSize(zoom);
  const [map, setMap] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchLockers = async () => {
      try {
        const response = await axios.get('https://serverlocker.winglet.app/lockers', {
          headers: {
            Authorization: `Bearer ${authState.token}`
          }
        });
        console.log('Fetched lockers data:', response.data); // Log the fetched data
        if (Array.isArray(response.data)) {
          // Sort lockers by size and number in ascending order
          const sortedLockers = response.data.sort((a, b) => {
            if (a.size === b.size) {
              return a.number - b.number;
            }
            return a.size === 's' ? -1 : 1;
          });
          setLockers(sortedLockers);
          console.log(`Fetched ${response.data.length} lockers from the database.`);
        } else {
          console.error("Fetched data is not an array:", response.data);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.removeItem('token'); // Remove invalid token
            navigate('/'); // Redirect to login on 401 Unauthorized
          } else if (error.response.status === 403) {
            console.error("Forbidden access - 403");
          } else {
            console.error("Error fetching lockers:", error.response.status, error.response.data);
          }
        } else {
          console.error("Error fetching lockers:", error.message);
        }
      }
    };

    fetchLockers();
  }, [authState, navigate]);

  const handleAssignLocker = (locker) => {
    setSelectedLocker(locker);
    setOpen(true);
  };

  const handleReleaseLocker = (lockerId) => {
    axios.put(`https://serverlocker.winglet.app/lockers/${lockerId}`, { user_id: null, is_occupied: 0 }, {
      headers: {
        Authorization: `Bearer ${authState.token}`
      }
    })
      .then(response => {
        setLockers(lockers.map(locker => locker.id === lockerId ? { ...locker, is_occupied: 0, user_id: null } : locker));
      })
      .catch(error => console.error('Error releasing locker:', error));
  };

  const handleLogout = () => {
    logout();
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };


  const handleSearch = () => {
    const searchTermLower = searchTerm.toLowerCase().trim();
    console.log('Searching for:', searchTermLower);
    console.log('Available lockers:', lockers);
  
    const foundLocker = lockers.find(locker => {
      console.log('Checking locker:', locker);
      return locker.number.toString().toLowerCase() === searchTermLower || 
             (locker.user_id && locker.user_id.toString().toLowerCase() === searchTermLower);
    });
  
    if (foundLocker && map) {
      const [y, x] = foundLocker.position;
      const zoom = map.getBoundsZoom([[y, x], [y, x]]);
      map.setView([y, x], zoom - 1);
      console.log('Locker found:', foundLocker);
    } else {
      console.error('Locker not found');
    }
  };

  const handleBadgeClick = (type) => {
    // Handle badge click logic here
  };

  const handleClose = () => {
    setOpen(false);
    setEmployeeId('');
    setSelectedLocker(null);
  };

  const handleSubmit = () => {
    if (selectedLocker) {
      axios.put(`https://serverlocker.winglet.app/lockers/${selectedLocker.id}`, { user_id: employeeId, is_occupied: 1 }, {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      })
        .then(response => {
          setLockers(lockers.map(locker => locker.id === selectedLocker.id ? { ...locker, is_occupied: 1, user_id: employeeId } : locker));
          handleClose();
        })
        .catch(error => console.error('Error assigning locker:', error));
    }
  };

  // Distribute lockers in a grid with a maximum of 10 columns and increased separation
  const maxColumns = 20;
  const smallLockerSize = 120; // Adjust as needed for separation
  const bigLockerSize = smallLockerSize * 1.2; // Adjust as needed for separation
  const gapBetweenGrids = 80; // Gap between small and big lockers

// Reverse the lockers array before calculating positions
const reversedLockers = [...lockers].reverse();

// Calculate positions for small lockers
const smallLockerPositions = reversedLockers
  .filter(locker => locker.size === 's')
  .map((locker, index) => {
    const row = Math.floor(index / maxColumns);
    const col = maxColumns - 1 - (index % maxColumns); // Invert the column order
    return {
      ...locker,
      position: [row * smallLockerSize, col * smallLockerSize],
    };
  });

// Calculate positions for big lockers
const bigLockerPositions = reversedLockers
  .filter(locker => locker.size === 'b')
  .map((locker, index) => {
    const row = Math.floor(index / maxColumns);
    const col = maxColumns - 1 - (index % maxColumns); // Invert the column order
    return {
      ...locker,
      position: [row * bigLockerSize, col * bigLockerSize + maxColumns * smallLockerSize + gapBetweenGrids],
    };
  });

// Combine positions
const lockerPositions = [...smallLockerPositions, ...bigLockerPositions];

  const smallAvailable = lockers.filter(locker => locker.size === 's' && !locker.is_occupied).length;
  const smallOccupied = lockers.filter(locker => locker.size === 's' && locker.is_occupied).length;
  const bigAvailable = lockers.filter(locker => locker.size === 'b' && !locker.is_occupied).length;
  const bigOccupied = lockers.filter(locker => locker.size === 'b' && locker.is_occupied).length;
  const totalAvailable = smallAvailable + bigAvailable;
  const totalOccupied = smallOccupied + bigOccupied;
  const totalLockers = lockers.length;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar position="static" sx={{ bgcolor: "#11111f" }}>
        <Toolbar>
          <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '20px' }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Locker Control System
          </Typography>
          <Button color="inherit" onClick={handleLogout} startIcon={<LogoutIcon />}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <div style={{ padding: '20px', flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: '0 1 auto', display: 'flex', justifyContent: 'space-between' }}>
          <Badge badgeContent={smallAvailable} color="success" onClick={() => handleBadgeClick('smallAvailable')}>
            <img src={smallAvailableImage} alt="Small Lockers Available" style={{ width: '90%', cursor: 'pointer' }} />
          </Badge>
          <Badge badgeContent={smallOccupied} color="error" onClick={() => handleBadgeClick('smallOccupied')}>
            <img src={smallOccupiedImage} alt="Small Lockers Occupied" style={{ width: '90%', cursor: 'pointer' }} />
          </Badge>
          <Badge badgeContent={bigAvailable} color='success' onClick={() => handleBadgeClick('bigAvailable')}>
            <img src={bigAvailableImage} alt="Big Lockers Available" style={{ width: '90%', cursor: 'pointer' }} />
          </Badge>
          <Badge badgeContent={bigOccupied} color="error" onClick={() => handleBadgeClick('bigOccupied')}>
            <img src={bigOccupiedImage} alt="Big Lockers Occupied" style={{ width: '90%', cursor: 'pointer' }} />
          </Badge>
        </div>
        <div style={{ marginBottom: '5px', marginTop: '10px', flex: '0 1 auto', textAlign: 'center', color: '#284268' }}>
          <Typography variant='body1'> Ocupados: {totalOccupied} | Livres: {totalAvailable} </Typography>
          <Divider style={{ margin: '5px' }} />
          <Typography variant='body1'> Total de Armários: {totalLockers} </Typography>
        </div>
        <TextField
          label="Buscar Colete"
          value={searchTerm}
          onChange={handleSearchTermChange}
          fullWidth
          placeholder="Search by locker number or employee ID"
          style={{ marginBottom: '20px', marginTop: '20px', flex: '0 1 auto', borderRadius: '10px' }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch();
            }
          }}
        />
<Button onClick={handleSearch}>Search</Button>


        <Divider sx={{ marginBottom: '20px' }} />
        <Box sx={{ height: '50hv', overflowY: 'hidden', display: 'flex', flexDirection: 'column' }}>
          <div style={{ height: '35rem', overflow: 'inherit' }}>
            <MapContainer 
              center={[500, 500]} 
              zoom={0} 
              style={{ height: '100%', width: '100%' }} 
              crs={L.CRS.Simple}
              minZoom={-2}
              maxZoom={3}
              whenCreated={setMap} // Attach map reference
            >
              <ZoomListener setZoom={setZoom} />
              {lockerPositions.map(locker => {
                const icon = locker.is_occupied 
                  ? (locker.size === 's' ? smallOccupiedIcon : bigOccupiedIcon)
                  : (locker.size === 's' ? smallFreeIcon : bigFreeIcon);

                const customIcon = new L.DivIcon({
                  html: `<div style="text-align: center; width: 70px; height: 70px; display: flex; flex-direction: column; align-items: center; justify-content: center; background: transparent;">
                           <div style="font-size: 14px; font-weight: bold; background: transparent;">${locker.is_occupied ? locker.user_id : locker.number}</div>
                            <img src="${icon.options.iconUrl}" style="width: 40px; height: 40px;" />
                           </div>`,
                  iconSize: [70, 70], // Fixed size to fit 7 characters
                  iconAnchor: [35, 35], // Center the icon
                  popupAnchor: [0, -35], // Adjust popup anchor as needed
                });

                return (
                  <Marker
                    key={locker.id}
                    position={locker.position}
                    icon={customIcon}
                  >
                    <Popup>
                      <div>
                        <h3>Locker {locker.number}</h3> {/* Display locker number */}
                        <p>Status: {locker.is_occupied ? 'Occupied' : 'Available'}</p>
                        {locker.is_occupied ? (
                          <Button variant="contained" color="error" onClick={() => handleReleaseLocker(locker.id)}>
                            Release Locker
                          </Button>
                        ) : (
                          <Button variant="contained" color="primary" onClick={() => handleAssignLocker(locker)}>
                            Assign Locker
                          </Button>
                        )}
                      </div>
                    </Popup>
                  </Marker>
                );
              })}
            </MapContainer>
          </div>
        </Box>
      </div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Assign Locker</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the employee ID to assign this locker.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Employee ID"
            type="text"
            fullWidth
            value={employeeId}
            onChange={(e) => setEmployeeId(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Assign
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}