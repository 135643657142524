import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, Divider, Button, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { format, startOfDay, endOfDay } from 'date-fns';
import jsPDF from 'jspdf';
import logoOrbital from '../assets/images/logo-orbital.png'; // Replace with your image path

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const LockerHistoryModal = ({ open, handleClose, history, lockerId }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredHistory, setFilteredHistory] = useState(history);

  useEffect(() => {
    const filterHistory = () => {
      if (!startDate && !endDate) return history;
      if (!startDate) {
        return history.filter(entry => {
          const entryDate = new Date(entry.dateTime);
          return entryDate <= endOfDay(endDate);
        });
      }
      if (!endDate) {
        return history.filter(entry => {
          const entryDate = new Date(entry.dateTime);
          return entryDate >= startOfDay(startDate);
        });
      }
      return history.filter(entry => {
        const entryDate = new Date(entry.dateTime);
        return entryDate >= startOfDay(startDate) && entryDate <= endOfDay(endDate);
      });
    };

    setFilteredHistory(filterHistory());
  }, [startDate, endDate, history]);

  const exportPDF = () => {
    const doc = new jsPDF();
    let yOffset = 30; // Initial offset from the top
    doc.setFontSize(16);
    doc.text(`Histórico do Armário ${lockerId}`, 80, 10);
    doc.line(10, 15, 200, 15); // Draw a line separator
    doc.setFontSize(12);

    filteredHistory.forEach((entry, index) => {
      doc.text(`Tipo: ${entry.type === 'assign' ? 'Utilizado' : 'Liberado'}`, 10, yOffset);
      yOffset += 10;
      if (entry.type === 'assign') {
        doc.text(`Pelo colete: ${entry.employeeId || 'N/A'}`, 10, yOffset);
        yOffset += 10;
      }
      doc.text(`Data e Hora: ${entry.dateTime ? new Date(entry.dateTime).toLocaleString() : 'N/A'}`, 10, yOffset);
      yOffset += 10;
      doc.text(`Responsável: ${entry.fullName || 'N/A'}, matrícula: ${entry.userBadge || 'N/A'}`, 10, yOffset);
      yOffset += 10;
      if (index < history.length - 1) {
        doc.line(10, yOffset, 200, yOffset); // Draw a line separator
        yOffset += 10;
      }
    });

    // Add the logo image to the PDF
    const img = new Image();
    img.src = logoOrbital;
    img.onload = () => {
      doc.addImage(img, 'PNG', 10, yOffset, 40, 10);
      doc.setFontSize(8);
      doc.text(`Relatório extraído em ${new Date().toLocaleString()}`, 10, yOffset + 25);
      doc.save(`locker_history_${lockerId}.pdf`);
    };
  };

  const resetDates = () => {
    setStartDate(null);
    setEndDate(null);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-title" variant="h6" component="h2" sx={{ textAlign: 'center' }}>
          Histórico do Armário {lockerId} {/* Use the lockerId in the title */}
        </Typography>
        <Divider sx={{ marginBottom: '10px' }} />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
            <DatePicker
              label="Data Inicial"
              value={startDate}
              onChange={(newValue) => setStartDate(newValue)}
              renderInput={(params) => <TextField {...params} sx={{ width: '45%' }} />}
              inputFormat="dd/MM/yyyy"
            />
            <DatePicker
              label="Data Final"
              value={endDate}
              onChange={(newValue) => setEndDate(newValue)}
              renderInput={(params) => <TextField {...params} sx={{ width: '45%' }} />}
              inputFormat="dd/MM/yyyy"
            />
          </Box>
        </LocalizationProvider>
        <Button variant="outlined" color="secondary" onClick={resetDates} fullWidth={true} sx={{ marginBottom: '10px' }}>
          Resetar Datas
        </Button>
        <Box sx={{ maxHeight: '60vh', overflowY: 'auto' }}>
          {filteredHistory.map((entry, index) => (
            <Box key={index} sx={{ marginBottom: '10px' }}>
              <Typography variant="body2">
                <strong>Tipo:</strong> {entry.type === 'assign' ? 'Utilizado' : 'Liberado'}
              </Typography>
              {entry.type === 'assign' && (
                <Typography variant="body2">
                  <strong>Pelo colete:</strong> {entry.employeeId || 'N/A'}
                </Typography>
              )}
              <Typography variant="body2">
                <strong>Data e Hora:</strong> {entry.dateTime ? format(new Date(entry.dateTime), 'dd/MM/yyyy HH:mm:ss') : 'N/A'}
              </Typography>
              <Typography variant="body2">
                <strong>Responsável:</strong> {entry.fullName || 'N/A'}, matrícula: {entry.userBadge || 'N/A'}
              </Typography>
              <Divider sx={{ marginTop: '10px' }} />
            </Box>
          ))}
        </Box>
        <Button variant="contained" disabled={history.length <= 0} color="primary" onClick={exportPDF} fullWidth={true} sx={{ marginTop: '10px', alignSelf: 'center' }}>
          Exportar para PDF
        </Button>
      </Box>
    </Modal>
  );
};

export default LockerHistoryModal;