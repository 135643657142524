import React, { useEffect, useState, useContext, useRef, useCallback } from 'react';
import axios from 'axios';
import {useMediaQuery, Divider, ListItem, ListItemText, Button, Typography, AppBar, Toolbar, Modal, Box, TextField, SpeedDial, SpeedDialAction, Snackbar, Alert, Badge, ListItemAvatar, Avatar, Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoutIcon from '@mui/icons-material/Logout';
import AddIcon from '@mui/icons-material/Add';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import IconButton from '@mui/material/IconButton';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import LuggageIcon from '@mui/icons-material/Luggage';
import HistoryIcon from '@mui/icons-material/History';
import Webcam from 'react-webcam';
import Tesseract from 'tesseract.js';
import {jwtDecode} from 'jwt-decode';
import { FixedSizeList as List } from 'react-window';
import InfiniteScroll from 'react-infinite-scroll-component';
import LockerHistoryModal from '../components/LockerModal';
import AllLockersHistoryModal from '../components/AllLockersHistoryModal'; // Ensure this import is present



// Import images
import logo from '../assets/images/logo.png';
import smallAvailableImage from '../assets/images/sa.png';
import smallOccupiedImage from '../assets/images/so.png';
import bigAvailableImage from '../assets/images/ba.png';
import bigOccupiedImage from '../assets/images/bo.png';
import { AuthContext } from '../context/authContext';
import Keyboard from '@mui/icons-material/Keyboard';
import UserProfileModal from '../components/UserProfileModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  maxWidth: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

export default function LockerControlSystemPage() {
  const [lockers, setLockers] = useState([]);
  const [open, setOpen] = useState(false);
  const [employeeId, setEmployeeId] = useState('');
  const [selectedLocker, setSelectedLocker] = useState(null);
  const [selectedLockerNumber, setSelectedLockerNumber] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [lockerSize, setLockerSize] = useState('s');
  const [smallAvailable, setSmallAvailable] = useState(0);
  const [smallOccupied, setSmallOccupied] = useState(0);
  const [bigAvailable, setBigAvailable] = useState(0);
  const [bigOccupied, setBigOccupied] = useState(0);
  const [totalOccupied, setTotalOccupied] = useState(0);
  const [totalAvailable, setTotalAvailable] = useState(0);
  const [totalLockers, setTotalLockers] = useState(0);
  const [badgeFilter, setBadgeFilter] = useState(''); // New state for badge filter
  const [inputMethod, setInputMethod] = useState('ocr'); // New state for input method
  const [confirmOpen, setConfirmOpen] = useState(false);
const [lockerToRelease, setLockerToRelease] = useState(null);
const [historyModalOpen, setHistoryModalOpen] = useState(false);
const [lockerHistory, setLockerHistory] = useState([]);
const [selectedLockerId, setSelectedLockerId] = useState(null);
const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
const [lockerData, setLockerData] = useState([]);
const [anchorEl, setAnchorEl] = useState(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
 // Add state for modal
// Add state for selected locker ID



  const webcamRef = useRef(null);
  const { authState } = useContext(AuthContext);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const [cameraError, setCameraError] = useState(null);
  const fullName = sessionStorage.getItem('fullName');
  const userStatus = sessionStorage.getItem('userStatus');  
  const userRole = sessionStorage.getItem('userRole');
  const userHistory = sessionStorage.getItem('userHistory')
  const userId = sessionStorage.getItem('userId')
  const userBadge = sessionStorage.getItem('userBadge')
  
  const navigate = useNavigate();

  const videoConstraints = {
    width: { ideal: 1280 },
    height: { ideal: 720 },
    facingMode: { ideal: "environment" }
  };

  const handleOpenHistoryModal = async () => {
    await fetchAllLockersHistory();
    setIsHistoryModalOpen(true);
    handleMenuClose();
  };

  const handleCloseHistoryModal = () => {
    setIsHistoryModalOpen(false);
  };

  const handleAvatarClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    setIsProfileModalOpen(true);
    handleMenuClose();
  };

  const handleLogoutClick = () => {
    handleLogout();
    handleMenuClose();
  };

  const fetchAllLockersHistory = async () => {
    try {
      // Fetch all lockers history
      const response = await axios.get('https://serverlocker.winglet.app/lockers', {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });
      // Filter out lockers with null or undefined history
      const filteredData = response.data.filter(locker => locker.locker_history !== null && locker.locker_history !== undefined);
      setLockerData(filteredData);
    } catch (error) {
      console.error('Error fetching all lockers history:', error);
    }
  };

  useEffect(() => {
    sessionStorage.setItem('fullName', authState.fullName);
    sessionStorage.setItem('userStatus', authState.userStatus);
    sessionStorage.setItem('userRole', authState.userRole);
    sessionStorage.setItem('userHistory', authState.userHistory);
    sessionStorage.setItem('userId', authState.userId);
    sessionStorage.setItem('userBadge', authState.badge);
  }, [authState]);

  const handleInputChange = useCallback((e) => {
    let value = e.target.value;

    value = value.replace(/\D/g, ''); // Remove non-numeric characters

    value.length > 3 ? setEmployeeId(`ORB${value}`) : setEmployeeId(value);
  }, []);

  const handleSearchTermChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if (!token) {
      localStorage.removeItem('token');
      navigate('/'); // Redirect to login if token is not found
      return;
    }
  
    let decoded;
    try {
      decoded = jwtDecode(token);
    } catch (error) {
      console.error('Invalid token:', error);
      localStorage.removeItem('token'); // Remove invalid token
      navigate('/'); // Redirect to login if token is invalid
      return;
    }
  
    const currentTime = Math.floor(Date.now() / 1000);
    if (decoded.exp < currentTime) {
      localStorage.removeItem('token'); // Remove expired token
      navigate('/'); // Redirect to login if token is expired
      return;
    }
  
    if (!authState.user) {
      localStorage.removeItem('token'); // Remove invalid token
      navigate('/'); // Redirect to login if user is not authenticated
      return;
    }
    
    const fetchLockers = async () => {
      try {
        const response = await axios.get('https://serverlocker.winglet.app/lockers', {
          headers: {
            Authorization: `Bearer ${authState.token}`
          }
        });
        if (Array.isArray(response.data)) {
          setLockers(response.data);
        } else {
          console.error("Fetched data is not an array:", response.data);
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 401) {
            localStorage.removeItem('token'); // Remove invalid token
            navigate('/'); // Redirect to login on 401 Unauthorized
          } else if (error.response.status === 403) {
            console.error("Forbidden access - 403");
          } else {
            console.error("Error fetching lockers:", error.response.status, error.response.data);
          }
        } else {
          console.error("Error fetching lockers:", error.message);
        }
      }
    };

    fetchLockers();
  }, [authState, navigate]);

  useEffect(() => {
    const calculateCounts = () => {
      const smallAvailableCount = lockers.filter(locker => !locker.is_occupied && locker.size === 's').length;
      const smallOccupiedCount = lockers.filter(locker => locker.is_occupied && locker.size === 's').length;
      const bigAvailableCount = lockers.filter(locker => !locker.is_occupied && locker.size === 'b').length;
      const bigOccupiedCount = lockers.filter(locker => locker.is_occupied && locker.size === 'b').length;
      const totalLockers = smallAvailableCount + bigAvailableCount + smallOccupiedCount + bigOccupiedCount;
      const percentOccupied = ((smallOccupiedCount + bigOccupiedCount) / totalLockers) * 100;
      const percentAvailable = ((smallAvailableCount + bigAvailableCount) / totalLockers) * 100;
      const formattedPercentOccupied = percentOccupied.toFixed(0); // Format to 2 decimal places
      const formattedPercentAvailable = percentAvailable.toFixed(0); // Format to 2 decimal places

      setSmallAvailable(smallAvailableCount);
      setSmallOccupied(smallOccupiedCount);
      setBigAvailable(bigAvailableCount);
      setBigOccupied(bigOccupiedCount);
      setTotalOccupied(formattedPercentOccupied);
      setTotalAvailable(formattedPercentAvailable);
      setTotalLockers(totalLockers);
    };

    calculateCounts();
  }, [lockers]);


  const handleLogout = () => {
    localStorage.removeItem('token');
    sessionStorage.clear();
    // Implement logout functionality
    navigate('/');
  };

  const handleOpen = (locker, lockerNumber) => {
    setSelectedLocker(locker);
    setSelectedLockerNumber(lockerNumber);
    setOpen(true);
    setShowCamera(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEmployeeId('');
    setShowCamera(false); // Reset camera state
  };

  const assignRandomLocker = async (size) => {
    try {
      const availableLockers = lockers.filter(locker => !locker.is_occupied && locker.size === size);
      if (availableLockers.length === 0) {
        setAlertMessage('Todos os armários estão ocupados');
        setAlertSeverity('error');
        setAlertOpen(true);
        return;
      }
      
      // Open modal to capture employee ID
      setLockerSize(size);
      setOpen(true);
      setShowCamera(true);
    } catch (error) {
      console.error('Error assigning locker:', error);
      setAlertMessage('Erro ao atribuir armário. Tente novamente!');
      setAlertSeverity('error');
      setAlertOpen(true);
    }
  };
  
  const handleAssign = async () => {
    try {
      const availableLockers = lockers.filter(locker => !locker.is_occupied && locker.size === lockerSize);
      if (availableLockers.length === 0) {
        setAlertMessage('Nenhum armário disponível');
        setAlertSeverity('error');
        setAlertOpen(true);        
        return;
      }
      const randomLocker = availableLockers[Math.floor(Math.random() * availableLockers.length)];
      console.log(randomLocker)
  
      const lockerHistory = {
        type: 'assign',
        fullName: fullName,
        userBadge: userBadge,
        dateTime: new Date().toISOString(),
        employeeId: employeeId
      };
  
      // Fetch current locker history
      const lockerResponse = await axios.get(`https://serverlocker.winglet.app/lockers/${randomLocker.id}`, {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });
  
      let currentHistory = lockerResponse.data.locker_history || [];
      if (typeof currentHistory === 'string') {
        currentHistory = JSON.parse(currentHistory);
      }
  
      // Ensure currentHistory is an array
      if (!Array.isArray(currentHistory)) {
        currentHistory = [];
      }
  
      // Append new history entry
      currentHistory.push(lockerHistory);
  
      // Send request to server to assign the locker with updated history
      await axios.put(`https://serverlocker.winglet.app/lockers/${randomLocker.id}`, {
        user_id: employeeId,
        is_occupied: "1",
        locker_history: JSON.stringify(currentHistory) // Stringify the updated history array
      }, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
          'Content-Type': 'application/json'
        }
      });
  
      // Fetch updated lockers
      const response = await axios.get('https://serverlocker.winglet.app/lockers', {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });
      if (Array.isArray(response.data)) {
        setLockers(response.data);
      } else {
        console.error("Fetched data is not an array:", response.data);
      }
      setAlertMessage(`Armário ${randomLocker.number} atribuído ao colete ${employeeId}`);
      setAlertSeverity('success');
      setAlertOpen(true);
      setOpen(false);
      setEmployeeId('');
      setSelectedLocker(null);
    } catch (error) {
      console.error("Error assigning locker:", error);
      setAlertMessage('Ops! Não foi possível atribuir o armário. Tente novamente!');
      setAlertSeverity('warning');
      setAlertOpen(true);
    }
  };

  const handleAssignLocker = () => {
    if (selectedLocker) {
      assignLockerToEmployee(selectedLocker.id, employeeId, selectedLocker.number);
      handleClose();
    }
  };

  const assignLockerToEmployee = async (lockerId, employeeId, lockerNumber) => {
    const lockerHistory = {
      type: 'assign',
      fullName: fullName,
      userBadge: userBadge,
      dateTime: new Date().toISOString(),
      employeeId: employeeId,
      lockerId: lockerId
    };
  
    try {
      // Fetch current locker history
      const lockerResponse = await axios.get(`https://serverlocker.winglet.app/lockers/${lockerId}`, {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });
  
      let currentHistory = lockerResponse.data.locker_history || [];
      if (typeof currentHistory === 'string') {
        currentHistory = JSON.parse(currentHistory);
      }
  
      // Ensure currentHistory is an array
      if (!Array.isArray(currentHistory)) {
        currentHistory = [];
      }
  
      // Append new history entry
      currentHistory.push(lockerHistory);
  
      // Send request to server to assign the locker with updated history
      await axios.put(`https://serverlocker.winglet.app/lockers/${lockerId}`, {
        user_id: employeeId,
        is_occupied: "1",
        locker_history: JSON.stringify(currentHistory) // Stringify the updated history array
      }, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
          'Content-Type': 'application/json'
        }
      });
  
      // Fetch updated lockers
      const response = await axios.get('https://serverlocker.winglet.app/lockers', {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });
      if (Array.isArray(response.data)) {
        setLockers(response.data);
      } else {
        console.error("Fetched data is not an array:", response.data);
      }
  
      setAlertMessage(`Armário ${lockerNumber} atribuído ao colete ${employeeId}`);
      setAlertSeverity('success');
      setAlertOpen(true);
      setOpen(false);
      setEmployeeId('');
      setSelectedLocker(null);
    } catch (error) {
      console.error('Error assigning locker:', error);
      setAlertMessage('Ops! Não foi possível atribuir o armário. Tente novamente!');
      setAlertSeverity('warning');
      setAlertOpen(true);
    }
  };

  const releaseLocker = (lockerNumber) => {
    setLockerToRelease(lockerNumber);
    setConfirmOpen(true);
  };

  const handleConfirmRelease = async () => {
    const lockerNumber = lockerToRelease;
    const lockerHistory = {
      type: 'release',
      fullName: fullName,
      userBadge: userBadge,
      dateTime: new Date().toISOString(),
      employeeId: employeeId
    };
    try {
      // Fetch current locker history
      const lockerResponse = await axios.get(`https://serverlocker.winglet.app/lockers/${lockerNumber}`, {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });
  
      let currentHistory = lockerResponse.data.locker_history || [];
      if (typeof currentHistory === 'string') {
        currentHistory = JSON.parse(currentHistory);
      }
  
      // Ensure currentHistory is an array
      if (!Array.isArray(currentHistory)) {
        currentHistory = [];
      }
  
      // Append new history entry
      currentHistory.push(lockerHistory);
  
      // Send request to server to release the locker with updated history
      await axios.put(`https://serverlocker.winglet.app/lockers/${lockerNumber}`, {
        user_id: null,
        is_occupied: 0,
        locker_history: JSON.stringify(currentHistory) // Stringify the updated history array
      }, {
        headers: {
          Authorization: `Bearer ${authState.token}`,
          'Content-Type': 'application/json'
        }
      });
  
      // Fetch updated lockers
      const response = await axios.get('https://serverlocker.winglet.app/lockers', {
        headers: {
          Authorization: `Bearer ${authState.token}`
        }
      });
      if (Array.isArray(response.data)) {
        setLockers(response.data);
      } else {
        console.error("Fetched data is not an array:", response.data);
      }
  
      setConfirmOpen(false);
      setAlertMessage(`Armário ${lockerNumber} agora está disponível`);
      setAlertSeverity('success');
      setAlertOpen(true);
      setBadgeFilter('');
    } catch (error) {
      console.error('Error releasing locker:', error);
      setAlertMessage('Ops! Não foi possível liberar o armário. Tente novamente!');
      setAlertSeverity('warning');
      setAlertOpen(true);
    } finally {
      setConfirmOpen(false);
      setLockerToRelease(null);
    }
  };

  const Row = ({ index, style }) => {
    const locker = filteredLockers[index];
  
    const handleLockerClick = async (lockerId) => {
      try {
        // Fetch locker history
        const response = await axios.get(`https://serverlocker.winglet.app/lockers/${lockerId}`, {
          headers: {
            Authorization: `Bearer ${authState.token}`
          }
        });
    
        let history = response.data.locker_history || '[]';
        if (typeof history === 'string') {
          history = JSON.parse(history);
        }
    
        setLockerHistory(history);
        setSelectedLockerId(lockerId); // Set the selected locker ID
        setHistoryModalOpen(true);
      } catch (error) {
        console.error('Error fetching locker history:', error);
      }
    };
  
    return (
      <div style={{ ...style, marginBottom: '10px' }}>
        <ListItem
          key={locker.number}
          sx={{ height: '50px', backgroundColor: locker.is_occupied ? '#F8333C' : '#44AF69', color: '#FFF', borderRadius: '10px' }}
          secondaryAction={
            <>
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={() => locker.is_occupied ? releaseLocker(locker.id) : handleOpen(locker, locker.number)}
            >
              {locker.is_occupied ? <PersonRemoveIcon sx={{ color: '#FFF' }} /> : <PersonAddAlt1Icon sx={{ color: '#FFF' }} />}
            </IconButton>
            </>
          }
           // Conditionally add onClick event
        >
          <ListItemAvatar>
            <Avatar sx={{ bgcolor: 'transparent' }}>
              {locker.size === 's' ? <PhoneIphoneIcon sx={{ color: '#FFF' }} /> : <LuggageIcon sx={{ color: '#FFF' }} />}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
primary={
  locker.is_occupied 
    ? `Ocupado - ${locker.user_id}` 
    : `Armário ${locker.number}`
}            
secondary={
  locker.is_occupied 
    ? `Armário ${locker.number}`
    : 'Disponível'
}
          />
        </ListItem>
      </div>
    );
  };
  
  
  const checkCameraPermission = useCallback(async () => {
    try {
      console.log('Requesting camera permission...');
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      stream.getTracks().forEach(track => track.stop());
      console.log('Camera permission granted.');
      return true;
    } catch (error) {
      console.error('Camera permission denied:', error);
      setAlertMessage('Permissão para usar a câmera foi negada. Por favor, autorize o uso da câmera.');
      setAlertSeverity('error');
      setAlertOpen(true);
      return false;
    }
  }, []);

  useEffect(() => {
    const handleCameraPermission = async () => {
      if (open && inputMethod === 'ocr') {
        const hasPermission = await checkCameraPermission();
        if (hasPermission) {
          setShowCamera(true);
        } else {
          setShowCamera(false);
        }
      }
    };

    handleCameraPermission();
  }, [open, inputMethod, checkCameraPermission]);

  const handleCloseModal = () => {
    setOpen(false);
    setEmployeeId('');
    setShowCamera(false); // Reset camera state
  };
  
  const handleInputMethodChange = useCallback((method) => {
    setInputMethod(method);
    if (method === 'ocr') {
      checkCameraPermission();
    } else {
      setShowCamera(false);
    }
  }, [checkCameraPermission]);

  
const captureAndRecognize = useCallback(async () => {
  if (webcamRef.current) {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageSrc) {
      try {
        const { data: { text } } = await Tesseract.recognize(
          imageSrc,
          'eng',
          { logger: m => console.log(m) }
        );
        
        const match = text.match(/ORB\s?\d+/);
        if (match) {
          setEmployeeId(match[0]);
          setInputMethod('text');
        }
      } catch (error) {
        console.error('OCR failed:', error);
      }
    }
  }
}, [setEmployeeId]);

  useEffect(() => {
    let interval;
    if (open && showCamera) {
      interval = setInterval(captureAndRecognize, 1000);
    }
    return () => clearInterval(interval);
  }, [open, showCamera, captureAndRecognize]);

  const handleCameraError = useCallback((error) => {
    console.error('Camera error:', error);
    setAlertMessage('Permissão para usar a câmera foi negada. Por favor, autorize o uso da câmera.');
    setAlertSeverity('error');
    setAlertOpen(true);
  }, []);

  useEffect(() => {
    if (open) {
      setInputMethod('text');
      setShowCamera(true);
    }
  }, [open]);

  const filteredLockers = lockers.filter(locker => {
    const searchTermUpper = searchTerm.toUpperCase();
    const lockerUserIdUpper = locker.user_id ? locker.user_id.toUpperCase() : '';
    const lockerNumberUpper = locker.number ? locker.number.toUpperCase() : '';
  
    // Considerar o filtro de badge e a pesquisa juntos
    const matchesBadgeFilter = !badgeFilter || (
      (badgeFilter === 'smallAvailable' && !locker.is_occupied && locker.size === 's') ||
      (badgeFilter === 'smallOccupied' && locker.is_occupied && locker.size === 's') ||
      (badgeFilter === 'bigAvailable' && !locker.is_occupied && locker.size === 'b') ||
      (badgeFilter === 'bigOccupied' && locker.is_occupied && locker.size === 'b')
    );
  
    const matchesSearchTerm = !searchTerm || lockerUserIdUpper === searchTermUpper || lockerNumberUpper === searchTermUpper;
  
    return matchesBadgeFilter && matchesSearchTerm;
  });
  

  const handleBadgeClick = (filter) => {
    setBadgeFilter(prevFilter => prevFilter === filter ? '' : filter);
    setSearchTerm('');
  };

  const getBadgeImgClass = (filter) => {
    return badgeFilter && badgeFilter !== filter ? 'badge-img grayscale' : 'badge-img';
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
      <AppBar position="static" sx={{ bgcolor: "#11111f" }}>
        <Toolbar>
          <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '20px' }} />
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          </Typography>
          <Avatar onClick={handleAvatarClick} style={{ cursor: 'pointer' }} />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleProfileClick}>Perfil</MenuItem>
<MenuItem onClick={handleOpenHistoryModal} disabled={userRole !== 'Admin'}>
  Histórico de Armários
</MenuItem>            <Divider />
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding:1, fontVariant:'body2'}}>
      <Typography sx={{ display: 'flex', flex: '0 1 auto'  }}/> Filtar por tipo e status:
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize:'10px' }}>
      <Typography sx={{ display: 'flex', flex: '0 1 auto'  }}/> Clique no tipo de armário para filtrar. Para remover o filtro, clique novamente.
      </Box>

      <div style={{ padding: '20px', flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>      
        <div style={{ flex: '0 1 auto', display: 'flex', justifyContent: 'space-between'}}>
        <Badge badgeContent={smallAvailable} max={9999} color="success" onClick={() => handleBadgeClick('smallAvailable')} className={getBadgeImgClass('smallAvailable')}>
      <img src={smallAvailableImage} alt="Small Lockers Available" className={getBadgeImgClass('smallAvailable')} style={{ width: '90%', cursor: 'pointer' }} />
    </Badge>
    <Badge badgeContent={smallOccupied} max={9999} color="error" onClick={() => handleBadgeClick('smallOccupied')} className={getBadgeImgClass('smallOccupied')}>
      <img src={smallOccupiedImage} alt="Small Lockers Occupied" className={getBadgeImgClass('smallOccupied')} style={{ width: '90%', cursor: 'pointer' }} />
    </Badge>
    <Badge badgeContent={bigAvailable} max={9999} color="success" onClick={() => handleBadgeClick('bigAvailable')} className={getBadgeImgClass('bigAvailable')}>
      <img src={bigAvailableImage} alt="Big Lockers Available" className={getBadgeImgClass('bigAvailable')} style={{ width: '90%', cursor: 'pointer' }} />
    </Badge>
    <Badge badgeContent={bigOccupied} max={9999} color="error" onClick={() => handleBadgeClick('bigOccupied')} className={getBadgeImgClass('bigOccupied')} >
      <img src={bigOccupiedImage} alt="Big Lockers Occupied" className={getBadgeImgClass('bigOccupied')} style={{ width: '90%', cursor: 'pointer' }} />
    </Badge>
        </div>
        
        <div style={{ marginBottom: '5px', marginTop:'10px', flex: '0 1 auto', textAlign: 'center', color: '#284268' }}>
          <Typography variant='a'> Ocupados: {totalOccupied}% ({smallOccupied + bigOccupied}) | </Typography>
          <Typography variant='a'> Livres: {totalAvailable}% ({smallAvailable + bigAvailable}) </Typography>
          <Divider style={{margin:'05px'}}/>
          <Typography variant='a'> Total de Armários: {totalLockers} </Typography>

        </div>
        
        <TextField
          label="Buscar Colete ou Armário"
          value={searchTerm}
          onChange={handleSearchTermChange}
          fullWidth
          autoFocus={true}
          placeholder='Digite o número do colete ou do armário'
          style={{ marginBottom: '20px', marginTop: '20px', flex: '0 1 auto', borderRadius: '10px' }}
        />
        <Divider sx={{
          marginBottom: '20px',
        }}/>
      <AllLockersHistoryModal
        open={isHistoryModalOpen}
        handleClose={() => setIsHistoryModalOpen(false)}
        lockerData={lockerData}
      />

        
        <Box sx={{height:'100hv', overflowY: 'hidden', display:'flex', flexDirection:'column'}}>
          <div style={{ height:'35rem', overflow:'inherit'  }}>
          <InfiniteScroll
              dataLength={lockers.length}

              endMessage={<p style={{ textAlign: 'center' }}>No more lockers</p>}
            >
              <List
                height={600}
                itemCount={filteredLockers.length}
                itemSize={60}
                width={'100%'}
                borderRadius={'10px'}
              >
                {Row}
              </List>
            </InfiniteScroll>
          </div>
        </Box>
        <Typography variant="a" style={{ flexGrow: 1, fontSize:'10px', textAlign:'center'}}>
          V0.1 "Beta" - by winglet - Powered by WFS|Orbital
        </Typography>
      </div>
      <SpeedDial
      ariaLabel="Reservar um armário"
      sx={{ position: 'fixed', bottom: 16, right: 16 }}
      icon={<AddIcon />}
      FabProps={{
        sx: {
          bgcolor: '#284268',
          '&:hover': {
            bgcolor: '#1A2E',
          }
        }
      }}
    >
      <SpeedDialAction
        icon={<CloseFullscreenIcon />}
        tooltipTitle="Pequeno"
        tooltipOpen={true}
        onClick={() => assignRandomLocker('s')}
      />
      <SpeedDialAction
        icon={<OpenInFullIcon />}
        name="big-locker"
        tooltipTitle="Grande"
        tooltipOpen={true}
        onClick={() => assignRandomLocker('b')}
      />
    </SpeedDial>
    <Modal
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ 
          ...style, 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          justifyContent: 'center',
          width: inputMethod === 'ocr' ? '90%' : '70%',
          maxWidth: '500px',
          height: 'auto',
          maxHeight: '90vh',
          overflow: 'hidden',
          borderRadius: '10px',
          padding: inputMethod ==='ocr' ? '10px' : '50px',
          paddingTop: inputMethod === 'ocr' ? '' : '10px',
          paddingBottom: inputMethod === 'ocr' ? '' : '10px',
        }}>
          <Typography id="modal-title" variant="h6" component="h2" sx={{ marginBottom: inputMethod === 'ocr'? '-60px':'1px', color: employeeId ? '#115e15' : '#284268', fontWeight: 'bold', textTransform: 'uppercase' }}>
            {!employeeId ? 'Capturar Colete' :  employeeId}
          </Typography>
          {inputMethod === 'ocr' ? (
            <>
              <div style={{ 
                position: 'relative', 
                width: '80%', 
                paddingTop: '56.25%', // 16:9 Aspect Ratio
                overflow: 'hidden',
              }}>
                {cameraError ? (
                  <Typography color="error">{cameraError}</Typography>
                ) : (
                  <div className='camera-overlay'>
                  <Webcam
                    audio={false}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    videoConstraints={videoConstraints}
                    onUserMediaError={handleCameraError}
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '10px',
                    }}
                  />
                  </div>
                )}
                <div style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    width: '75%',
                    height: '20%',
                    transform: 'translate(-50%, -50%)',
                    pointerEvents: 'none',
                    borderRadius: '10px',
                    backgroundColor: !employeeId ? 'rgba(255, 255, 255, 0.438)' : 'rgba(58, 212, 58, 0.356)' 
                }}>
                  <Typography variant="a" component={isSmallScreen ? 'p' : 'p' } sx={{fontSize:'12px', color: !employeeId ? 'rgba(240, 248, 255, 0.788)' : 'transparent', textAlign: 'center', marginTop: isSmallScreen ? -2 : -5 }}>
                    ÁREA DE CAPTURA
                  </Typography>
                </div>
              </div>
            </>
          ) : (

            <TextField
              label="Número do colete"
              placeholder='Digite apenas os números do colete'
              value={employeeId}
              inputMode='numeric'
              maxLength='7'
              onChange={handleInputChange}
              fullWidth
              autoFocus='true'
              style={{ marginTop: 10 }}
            />

          )}
      <div className="icon-button-container">
        <IconButton
          variant="contained"
          disabled={employeeId }
          onClick={() => handleInputMethodChange(inputMethod === 'ocr' ? 'text' : 'ocr')}
          sx={{ marginTop: inputMethod === 'ocr' ? '-1' : '-5px', color:'#284268'}}
        >
          {inputMethod === 'ocr' ? <Keyboard/> : <CameraAltIcon sx={{visibility: !employeeId || cameraError ? 'hidden' : 'visible'}}/>}
        </IconButton>
      </div>
    <Button
      variant="contained"
      color={employeeId === '' ? 'error' : 'success'}
      onClick={selectedLocker ? handleAssignLocker : handleAssign}
      style={{ marginTop: inputMethod === 'ocr' ? -40 : 10 }}
      disabled={!employeeId}
    >
      GUARDAR {employeeId}
    </Button>
  </Box>
</Modal>
<Modal
  open={confirmOpen}
  onClose={() => setConfirmOpen(false)}
  aria-labelledby="confirm-modal-title"
  aria-describedby="confirm-modal-description"
>
  <Box sx={{ ...style, maxWidth: 300 }}>
    <Typography id="confirm-modal-title" variant="h6" component="h2" sx={{textAlign:'center'}}>
      <strong>ARMÁRIO {lockerToRelease}</strong>
    </Typography>
    <Divider sx={{marginBottom: '10px'}}/>
    <Typography id="confirm-modal-description" sx={{ mt: 2 }}>
      Confirma a liberação deste armário?
    </Typography>

    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
      <Button variant="contained" color="error" onClick={() => setConfirmOpen(false)}>
        Não
      </Button>
      <Button variant="contained" color="success" onClick={handleConfirmRelease}>
        Sim
      </Button>

    </Box>
    <Typography id="confirm-modal-description" variant="caption" component="h2" sx={{textAlign:'center', marginTop:'20px'}}>
      Operação realizada por: {fullName} às {new Date().toLocaleTimeString()}
    </Typography>
  </Box>
  
</Modal>
{isProfileModalOpen && (
        <UserProfileModal 
          open={isProfileModalOpen} 
          onClose={() => setIsProfileModalOpen(false)} 
          fullName={fullName}
          userStatus={userStatus}
          userId={userId}
          userBadge={userBadge}
        />
      )}
{historyModalOpen && <AllLockersHistoryModal open={historyModalOpen} onClose={() => setHistoryModalOpen(false)} />}
<LockerHistoryModal
        open={historyModalOpen}
        handleClose={handleCloseHistoryModal}
        history={lockerHistory}
        lockerId={selectedLockerId}
      />
    <Snackbar open={alertOpen} autoHideDuration={6000} onClose={() => setAlertOpen(false)}>
      <Alert onClose={() => setAlertOpen(false)} severity={alertSeverity} sx={{ width: '100%' }}>
        {alertMessage}
      </Alert>
    </Snackbar>
    <style>{`
      @media (max-width: 600px) {
        .MuiListItem-root {
          padding: 8px !important;
        }
        .MuiButton-root {
          width: 100%;
        }
      }
    `}</style>
  </div>
);
}